<template>
    <div class="BlackScreen">
        <div id="sshScreen" class="sshScreen">
            <!-- This is where the remote screen will appear-->
        </div>
        <Menu :device="device"></Menu>
        <FireBridgeInfo  :fireBridge="fireBridge" :loaded="true" :onReconnect="reconnect" :onExit="exit"/>
    </div>
</template>

<script>
    import '@/sys/SshCore/css/xterm.css'
    import * as SSH from '@/sys/SshCore/combinedJS.comb';
    import FireBridge from '@/sys/FireBridge/FireBrige';
    import FireBridgeInfo from '@/views/FireBridgeInfo/FireBridgeInfo';
    import Menu from '../Menu'

    export default {
        components:{FireBridgeInfo, Menu},
        data() {
            return {
                fireBridge:null,
                desktopName:null,
                showMenu:false,
                fireBridge:new FireBridge(this.device._id,"SSH"),
            }
        },
        props: {
            device:{
                type: Object,
                default:null
            }
        },
        methods:{
            exit(){
                this.$router.push({ name:'AcessType', params: { device:this.device }});
            },
            reconnect(){
                console.log("Reconnect");
                SSH.initSSH(this.fireBridge);
                this.fireBridge.connect();
            }
        },
        mounted(){
            if(this.device!=null){
                SSH.initSSH(this.fireBridge);
                this.fireBridge.connect();
            }else{
                console.log("Faltou as informações do dispositivo");
                router.push({path:'/ListOfDevices'});
            }
        }
    }
</script>

<style scoped>
    .BlackScreen{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #36393F;
    }
    .sshScreen{
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
    }
</style>